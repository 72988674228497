<template>
	<div class="container">
		<web-header />
		<div class="content">
			<router-title :menuList="menuList[0]" :list="menuList[1]" />
			<!-- 直播区域 -->
			<div class="live_area">
				<!-- 观看区 -->
				<div class="see_live">
					<div class="top">
						<div class="name">{{liveDetails.name}}</div>
						<div class="people">
							<img src="../../assets/imgs/people.png" alt="">
							<span>{{liveDetails.viewPeople}}在线</span>
						</div>
					</div>
					<iframe :src="liveUrl" class="bom" frameborder="0"></iframe>
				</div>
				<!-- 聊天区 -->
				<div class="chat_live">
					<div class="chat_title">聊天</div>
					<div class="chat_content">
						<p v-for="it in commentList" :key="it.id">
							<span>{{it.userName}}：</span>
							{{it.content}}
						</p>
					</div>
					<div class="chat_send">
						<div class="loginStyle" :style="isLogin ? 'display:none' : ''">
							<span @click="toLogin">登录</span>
							发弹幕
						</div>
						<el-input placeholder="请输入内容..." v-model="content"></el-input>
						<el-button @click="sendEvt">发送</el-button>
					</div>
				</div>
			</div>
			<!-- 往期回看 -->
			<div class="recording_area" v-if="liveRecording.length > 0">
				<div class="title">往期回看</div>
				<div class="recording_list">
					<div v-for="(item,index) in liveRecording" :key="index" class="recording_item">
						<div class="time">{{item.createTime.split(' ')[0]}} {{item.createTime.split(' ')[1]}}</div>
						<div class="name">{{item.videoName}}</div>
						<div class="cover" @click="handleLiveReplay(item,index)">
							<img :src="item.recordSurfacePlot" alt="">
							<span>{{timeData(item.videoTime)}}</span>
						</div>
					</div>
				</div>
				<el-pagination class="pagination" background layout="prev, pager, next" :total="liveRecording.length"
					:page-size="15" />
			</div>
		</div>
		<web-footer />

	</div>
</template>

<script>
	import WebFooter from '../../components/webFooter.vue'
	import webHeader from '../../components/webHeader.vue'
	import RouterTitle from "../../components/routerTitle.vue";
	import {
		mapState
	} from 'vuex';
	export default {
		components: {
			webHeader,
			WebFooter,
			RouterTitle
		},
		data() {
			return {
				menuList: ["首页", "直播详情"],
				liveUrl: '',
				content: '',
				commentList: [],
				timeArr: [],
				liveDetails: {}, //直播详情
				liveRecording: [], //直播回看列表
			}
		},
		computed: {
			...mapState(['isLogin'])
		},
		created() {
			this.getLiveUrl();
			this.getCommentList()
			this.getLiveInfo()
			// //获取直播回看列表
			// this.id = JSON.parse(localStorage.getItem('liveRecording')).id
			// this.liveRecording = JSON.parse(localStorage.getItem('liveRecording')).list
			// if (this.liveRecording && this.liveRecording.length > 0) {
			// 	this.count = this.liveRecording.length
			// 	this.liveRecording.map(it => {
			// 		it.timeArr = it.time.split('/')
			// 		it.month = it.timeArr[1]
			// 		it.days = it.timeArr[2].split(' ')[0]
			// 		it.hours = it.timeArr[2].split(' ')[1]
			// 	})
			// }
		},

		// mounted() {
		// 	//获取直播地址
		// 	if (this.$route.query.data.id) {
		// 		this.$http.post('onLive', {
		// 			liveId: this.$route.query.data.id
		// 		}).then((res) => {
		// 			if (res.code == 200) {
		// 				this.liveUrl = res.data
		// 			} else {
		// 				this.$message.error(res.message);
		// 			}
		// 		})
		// 	}
		// },
		methods: {
			// 时间转换
			timeData(time) {
				var hh;
				var mm;
				var ss;
				//传入的时间为空或小于0
				if (time == null || time < 0) {
					return;
				}
				//得到小时
				hh = time / 3600 | 0;
				time = parseInt(time) - hh * 3600;
				if (parseInt(hh) < 10) {
					hh = "0" + hh;
				}
				//得到分
				mm = time / 60 | 0;
				//得到秒
				ss = parseInt(time) - mm * 60;
				if (parseInt(mm) < 10) {
					mm = "0" + mm;
				}
				if (ss < 10) {
					ss = "0" + ss;
				}
				// if (hh == '00') return mm + ":" + ss
				// if (hh == '00' && mm == '00') return ss
				return hh + ":" + mm + ":" + ss;
			},
			//去登录页面
			toLogin() {
				this.$router.push({
					path: '/login',
					// query: {
					// 	isUpdate: 1,
					// 	index: 1
					// }
				})
			},
			//去直播回看
			handleLiveReplay(item, i) {
				localStorage.setItem('liveReplay', JSON.stringify(this.liveRecording))
				this.$router.push({
					path: '/liveViews',
					query: {
						index: i
					}
				})
			},
			// 获取直播地址
			getLiveUrl() {
				this.$http.post('onLive', {
					liveId: this.$route.query.id
				}).then((res) => {
					if (res.code == 200) {
						this.liveUrl = res.data
					} else {
						this.$message.error(res.message);
					}
				})
			},
			// 获取直播详情
			getLiveInfo() {
				this.$http.post('liveById', {
					id: this.$route.query.id
				}).then(res => {
					if (res.code === 200) {
						this.liveDetails = res.data;
						if (res.data.list && res.data.list.length > 0) {
							this.liveRecording = res.data.list || []
						}
					} else {
						this.$message.error(res.message)
					}
				})
			},
			//获取评论列表
			async getCommentList() {
				await this.$http.post('userLiveGetList', {
					id: this.$route.query.id,
					pageNumber: 1,
					pageSize: 9999999
				}).then(res => {
					if (res.code === 200) {
						this.commentList = res.data.records
					}
				})
			},
			//发送弹幕
			sendEvt() {
				if (this.content) {
					this.$http.post('userLiveAdd', {
						content: this.content,
						liveId: this.$route.query.id
					}).then(res => {
						if (res.code === 200) {
							this.$message.success('评论成功！')
							this.content = ''
							this.getCommentList()
						} else {
							this.$message.error(res.message)
						}
					})
				} else {
					this.$message.error('评论不能为空！')
				}

			},
		}
	}
</script>

<style lang="less" scoped>
	.container {
		background: #EEEEEE;
	}

	.content {
		padding: 0 74px;
	}

	.live_area {
		display: flex;
		margin-bottom: 63px;

		.see_live {
			flex: 2;

			.top {
				height: 81px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 0 90px 0 45px;
				background: #ffffff;

				.name {
					font-size: 24px;
					font-weight: bold;
					color: #333333;
				}

				.people {
					img {
						width: 26px;
						height: 22px;
					}

					span {
						font-size: 20px;
						font-weight: 500;
						color: #333333;
						margin-left: 14px;
					}
				}
			}

			.bom {
				width: 100%;
				height: 815px;
				background-color: #333333;
			}
		}

		.chat_live {
			margin-left: 19px;
			width: 329px;
			height: 896px;
			background: #fff;

			.chat_title {
				width: 100%;
				height: 44px;
				font-size: 20px;
				font-weight: bold;
				line-height: 44px;
				padding-left: 15px;
				background: #FFFFFF;
				box-shadow: 0px 0px 13px 0px rgba(53, 53, 53, 0.13);
			}

			.chat_content {
				height: 792px;
				width: 100%;
				overflow: auto;
				font-size: 14px;

				p {
					margin: 15px;
				}

				span {
					color: #2992FF;
				}
			}

			.chat_send {
				position: relative;
				display: flex;
				width: 100%;
				height: 50px;
				padding: 5px;
				border-top: 1px solid #EEEEEE;

				.loginStyle {
					position: absolute;
					height: 50px;
					width: 80%;
					background: #eee;
					z-index: 100;
					font-size: 14px;
					line-height: 50px;
					padding-left: 14px;
					color: #999;

					span {
						color: #39AEFF;
						cursor: pointer;
					}
				}

				/deep/input {
					display: block;
					height: 50px;
					border-radius: 4px;
					border: 1px solid #eee;
				}

				button {
					display: block;
					width: 20%;
					height: 50px;
					background: #39AEFF;
					border-radius: 1px 4px 4px 1px;
					color: #fff;
				}
			}
		}
	}

	.recording_area {
		background: #FFFFFF;
		width: 80vw;
		margin: auto;

		.title {
			height: 72px;
			line-height: 72px;
			box-shadow: 0px 0px 13px 0px rgba(53, 53, 53, 0.13);
			padding-left: 30px;
			font-size: 20px;
			font-weight: 500;
			color: #333333;
			position: relative;

			&:before {
				content: '';
				display: inline-block;
				width: 5px;
				height: 47px;
				background: #39AEFF;
				position: absolute;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
			}
		}

		.recording_list {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-around;
			padding: 27px 40px 0;

			.recording_item {
				width: 33.3%;
				// margin-bottom: 29px;
				display: flex;
				flex-direction: column;
				position: relative;
				padding-left: 19px;
				border-left: 1px solid #39AEFF;
				padding-bottom: 29px;

				&:nth-last-child(1),
				&:nth-last-child(2),
				&:nth-last-child(3) {
					// align-items: flex-start;
					padding-bottom: 0;
				}

				.cicle {
					display: block;
					width: 5px;
					height: 5px;
					background: #39AEFF;
					border-radius: 50%;
					position: absolute;
					left: -3px;
					// top: 7px;
				}

				.time {
					font-size: 14px;
					font-weight: 500;
					color: #333333;
					margin-bottom: 19px;
				}

				.name {
					font-size: 16px;
					color: #999999;
					margin-bottom: 20px;
				}

				.cover {
					width: 223px;
					height: 140px;
					position: relative;
					cursor: pointer;

					img {
						width: 100%;
						height: 100%;
					}

					span {
						position: absolute;
						bottom: 0;
						right: 0;
						font-size: 14px;
						color: #FFFFFF;
						background: rgba(0, 0, 0, .57);
						border-radius: 10px 0px 10px 0px;
						padding: 9px 15px;
					}
				}

			}
		}

		.pagination {
			text-align: center;
			padding: 50px 0 48px;

			/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
				background: #39AEFF;
			}
		}
	}

	// .content {
	// 	background: #EEEEEE;
	// }

	// .info {
	// 	width: 1772px;
	// 	margin: auto;

	// 	.infoTitle {
	// 		padding: 0 75px;
	// 		display: flex;
	// 		justify-content: space-between;
	// 		height: 90px;
	// 		line-height: 90px;
	// 		background: #fff;
	// 		font-size: 24px;
	// 		color: #333333;
	// 		font-weight: bold;

	// 		.people {
	// 			font-weight: 500;
	// 			font-size: 20px;
	// 			color: #333333;
	// 		}

	// 		.img {
	// 			display: inline-block;
	// 			width: 26px;
	// 			height: 22px;
	// 			margin-right: 14px;
	// 		}
	// 	}

	// 	.live {
	// 		width: 1400px;
	// 		height: 810px;
	// 		background-color: #333333;
	// 	}

	// 	.lookBack {
	// 		width: 100%;
	// 		height: 72px;
	// 		line-height: 72px;
	// 		box-shadow: 0px 0px 13px 0px rgba(53, 53, 53, 0.5);
	// 		background: #FFFFFF;
	// 		border-left: 5px solid #39AEFF;
	// 		margin-top: 20px;
	// 		border-bottom: 1px solid #eee;

	// 		span {
	// 			display: inline-block;
	// 			padding-left: 30px;
	// 		}
	// 	}
	// }

	// .rightList {
	// 	display: flex;
	// 	justify-content: space-between;
	// 	background: #fff;
	// }

	// .rightOne {
	// 	width: 100%;
	// 	margin-bottom: 160px;
	// 	display: flex;
	// 	flex-wrap: wrap;
	// }

	// .cardCls:last-child {
	// 	padding-bottom: 20px;
	// }

	// .cardCls {
	// 	border-left: 1px solid#39AEFF;
	// 	font-size: 16px;
	// 	font-family: PingFang SC;
	// 	font-weight: 400;
	// 	color: #333333;
	// 	padding-bottom: 42px;
	// 	position: relative;
	// 	padding-left: 15px;
	// 	line-height: .3;
	// 	margin: 0 150px;

	// 	.lookBackTitle {
	// 		margin-top: 36px;
	// 		font-size: 16px;
	// 		color: #999;
	// 		margin-bottom: 36px;
	// 	}

	// 	.lookBackImg {
	// 		width: 223px;
	// 		position: relative;
	// 		cursor: pointer;

	// 		img {
	// 			width: 223px;
	// 			height: 140px;
	// 		}

	// 		p {
	// 			width: 75px;
	// 			height: 30px;
	// 			line-height: 30px;
	// 			text-align: center;
	// 			position: absolute;
	// 			font-size: 14px;
	// 			border-radius: 10px 0px 10px 0px;
	// 			background-color: #000000;
	// 			color: #FFFFFF;
	// 			opacity: 0.57;
	// 			right: 9px;
	// 			bottom: 6px;
	// 		}
	// 	}

	// 	.cicle {
	// 		display: block;
	// 		width: 5px;
	// 		height: 5px;
	// 		background: #39AEFF;
	// 		border-radius: 50%;
	// 		position: absolute;
	// 		left: -3px;
	// 		top: 0;
	// 	}

	// 	.shikan {
	// 		position: absolute;
	// 		right: 100px;
	// 		top: -8px;
	// 		width: 47px;
	// 		height: 21px;
	// 		border: 1px solid #FF0000;
	// 		border-radius: 11px;
	// 		text-align: center;
	// 		line-height: 21px;
	// 		font-size: 12px;
	// 		font-family: PingFang SC;
	// 		font-weight: bold;
	// 		color: #39AEFF;
	// 		cursor: pointer;
	// 	}

	// 	.time {
	// 		position: absolute;
	// 		right: 0;
	// 		top: -6px;
	// 		display: flex;
	// 		line-height: 1;
	// 		cursor: pointer;

	// 		span {
	// 			display: block;

	// 			img {
	// 				width: 16px;
	// 				height: 16px;
	// 				margin-right: 10px;
	// 			}
	// 		}
	// 	}
	// }

	// .pagination {
	// 	text-align: center;
	// 	padding-bottom: 57px;
	// 	margin-bottom: 57px;
	// 	background: #fff;

	// 	/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
	// 		background: #39AEFF;
	// 	}
	// }

	// .chatStyle {
	// 	display: flex;
	// 	justify-content: space-between;

	// 	.chatClass {
	// 		width: 330px;
	// 		height: 900px;
	// 		background: #fff;

	// 		.chatTitle {
	// 			width: 100%;
	// 			height: 44px;
	// 			font-size: 20px;
	// 			font-weight: bold;
	// 			line-height: 44px;
	// 			padding-left: 15px;
	// 			background: #FFFFFF;
	// 			box-shadow: 0px 0px 13px 0px rgba(53, 53, 53, 0.13);
	// 		}

	// 		.chatContent {
	// 			height: 792px;
	// 			width: 100%;
	// 			overflow: auto;
	// 			font-size: 14px;

	// 			p {
	// 				margin: 15px;
	// 			}

	// 			span {
	// 				color: #2992FF;
	// 			}
	// 		}

	// 		.chatSend {
	// 			position: relative;
	// 			display: flex;
	// 			width: 100%;
	// 			height: 50px;
	// 			padding: 5px;
	// 			border-top: 1px solid #EEEEEE;

	// 			.loginStyle {
	// 				position: absolute;
	// 				height: 50px;
	// 				width: 80%;
	// 				background: #eee;
	// 				z-index: 100;
	// 				font-size: 14px;
	// 				line-height: 50px;
	// 				padding-left: 14px;
	// 				color: #999;

	// 				span {
	// 					color: #39AEFF;
	// 					cursor: pointer;
	// 				}
	// 			}

	// 			/deep/input {
	// 				display: block;
	// 				height: 50px;
	// 				border-radius: 4px;
	// 				border: 1px solid #eee;
	// 			}

	// 			button {
	// 				display: block;
	// 				width: 20%;
	// 				height: 50px;
	// 				background: #39AEFF;
	// 				border-radius: 1px 4px 4px 1px;
	// 				color: #fff;
	// 			}
	// 		}
	// 	}
	// }
	// .rightList {
	// 	display: flex;
	// 	justify-content: space-between;
	// 	background: #fff;
	// }

	// .rightOne {
	// 	width: 100%;
	// 	margin-bottom: 160px;
	// 	display: flex;
	// 	flex-wrap: wrap;
	// }

	// .cardCls:last-child {
	// 	padding-bottom: 20px;
	// }

	// .cardCls {
	// 	border-left: 1px solid#39AEFF;
	// 	font-size: 16px;
	// 	font-family: PingFang SC;
	// 	font-weight: 400;
	// 	color: #333333;
	// 	padding-bottom: 42px;
	// 	position: relative;
	// 	padding-left: 15px;
	// 	line-height: .3;
	// 	margin: 0 150px;

	// 	.lookBackTitle {
	// 		margin-top: 36px;
	// 		font-size: 16px;
	// 		color: #999;
	// 		margin-bottom: 36px;
	// 	}

	// 	.lookBackImg {
	// 		width: 223px;
	// 		position: relative;
	// 		cursor: pointer;

	// 		img {
	// 			width: 223px;
	// 			height: 140px;
	// 		}

	// 		p {
	// 			width: 75px;
	// 			height: 30px;
	// 			line-height: 30px;
	// 			text-align: center;
	// 			position: absolute;
	// 			font-size: 14px;
	// 			border-radius: 10px 0px 10px 0px;
	// 			background-color: #000000;
	// 			color: #FFFFFF;
	// 			opacity: 0.57;
	// 			right: 9px;
	// 			bottom: 6px;
	// 		}
	// 	}

	// 	.cicle {
	// 		display: block;
	// 		width: 5px;
	// 		height: 5px;
	// 		background: #39AEFF;
	// 		border-radius: 50%;
	// 		position: absolute;
	// 		left: -3px;
	// 		top: 0;
	// 	}

	// 	.shikan {
	// 		position: absolute;
	// 		right: 100px;
	// 		top: -8px;
	// 		width: 47px;
	// 		height: 21px;
	// 		border: 1px solid #FF0000;
	// 		border-radius: 11px;
	// 		text-align: center;
	// 		line-height: 21px;
	// 		font-size: 12px;
	// 		font-family: PingFang SC;
	// 		font-weight: bold;
	// 		color: #39AEFF;
	// 		cursor: pointer;
	// 	}

	// 	.time {
	// 		position: absolute;
	// 		right: 0;
	// 		top: -6px;
	// 		display: flex;
	// 		line-height: 1;
	// 		cursor: pointer;

	// 		span {
	// 			display: block;

	// 			img {
	// 				width: 16px;
	// 				height: 16px;
	// 				margin-right: 10px;
	// 			}
	// 		}
	// 	}
	// }
</style>
